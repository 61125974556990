<script>
import Confirmation from '@/components/Confirmation.vue'
import { roles } from '@/utils/enums';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';

export default {
  components: {
    Confirmation
  },

  apollo: {
    users: {
      query: gql`
        query Users {
          users {
            id
            name
            email
            role
            customer {
              id
              name
            }
          }
        }
      `,

      fetchPolicy: 'cache-and-network',
    },
  },

  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Cliente',
          value: 'customer.name',
          sortable: false,
        },
        {
          text: 'Nome',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false,
        },
        {
          text: 'Perfil',
          value: 'role',
          sortable: false,
        },
        {
          text: 'Ações',
          value: '_actions',
          sortable: false,
          align: 'center',
        },
      ],
    };
  },

  methods:{
    async remove(id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid! $date: timestamptz) {
              update_users_by_pk(
                pk_columns: { id: $id },
                _set: {
                  deleted_at: $date
                }
              ) {
                id
              }
            }
          `,
          variables: {
            id,
            date: new Date()
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Removido com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
        this.$apollo.queries.users.refresh()
      } catch (e) {
        console.error(e);
      }
    },

    getRoleLabel (role) {
      return roles[role].label
    }
  }
};
</script>

<template>
  <div>
    <h1 class="mb-5">Usuários</h1>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :loading="$apollo.loading"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Procurar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn color="primary" dark to="/usuarios/new"> Novo </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.role`]="{ item }">
          {{ getRoleLabel(item.role) }}
        </template>
        <template v-slot:[`item._actions`]="{ item }">
          <v-btn text icon color="primary" :to="`/usuarios/${item.id}`">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <confirmation @success="() => remove(item.id)">
            <v-btn text icon color="error">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </confirmation>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
